@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800');
* {
  box-sizing: border-box;
}
body {
  /* background: #081827 !important; */
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: 'Fira Sans', sans-serif;
}
h1,h2,h3,h4,h5,h6,label,span,td {
  font-weight: 400;
  font-family: 'Fira Sans', sans-serif;
}
.dd{
      font-weight: 400;
      font-family: 'Fira Sans', sans-serif;
      color: #ffffff;
}
body, html, .App, #root, .auth-wrapper {
  width: 100%;
  height: 100%;
}
.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}
.auth-inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all .3s;
}
.auth-wrapper .form-control:focus {
  /* border-color: #167bff; */
  box-shadow: none;
}

.custom-control-label {
  font-weight: 400;
}
