.vitreFumee-status {
    padding: 4px 8px;
    border-radius: 4px;
    font-weight: bold;
  }
  
  .activeStatus {
    background-color: green;
    color: white;
  }
  
  .inactiveStatus {
    background-color: red;
    color: white;
  }
  