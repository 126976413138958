@import url(https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800);
.vitreFumee-status {
    padding: 4px 8px;
    border-radius: 4px;
    font-weight: bold;
  }
  
  .activeStatus {
    background-color: green;
    color: white;
  }
  
  .inactiveStatus {
    background-color: red;
    color: white;
  }
  
.columns {
    display: flex;
    flex-wrap: wrap;
  }
  
  .column {
    flex: 1 1;
    padding: 0.75rem;
  }
  
  .field {
    margin-bottom: 1rem;
  }
  
.vignette-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh; /* Ensure the container takes full height of the viewport */
    margin: 0 auto; /* Center horizontally */
}

  
  .vignette {
    position: relative;
    width: 350px;
    height: 350px;
  }
  
  .outer-circle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  
  .inner-circle {
    position: absolute;
    top: 10%;
    left: 10%;
    width: 80%;
    height: 80%;
    border-radius: 50%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  
  .logo-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('/policeLogo.png') no-repeat center center;
    background-size: cover;
    opacity: 0.2;
  }
  
  .vignette-gsr {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 55px;
    z-index: 1;
  }
  
  .vignette-plaque {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
    z-index: 1;
  }
  
  .vignette-svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .vignette-title {
    font-size: 14px;
    font-weight: bold;
    fill: white;
  }
  
  .vignette-title-arabic {
    position: absolute;
    bottom: 20%;
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: white;
  }
  
  .vignette-details {
    position: absolute;
    bottom: 10%;
    width: 100%;
    text-align: center;
    font-size: 12px;
    z-index: 1;
  }
  
  .vignette-details p {
    margin: 5px 0;
  }
  
  .qr-code {
    margin-top: 10px;
    z-index: 1;
  }
  @media print {
    .no-print {
      display: none;
    }
  }
  
* {
  box-sizing: border-box;
}
body {
  /* background: #081827 !important; */
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: 'Fira Sans', sans-serif;
}
h1,h2,h3,h4,h5,h6,label,span,td {
  font-weight: 400;
  font-family: 'Fira Sans', sans-serif;
}
.dd{
      font-weight: 400;
      font-family: 'Fira Sans', sans-serif;
      color: #ffffff;
}
body, html, .App, #root, .auth-wrapper {
  width: 100%;
  height: 100%;
}
.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}
.auth-inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all .3s;
}
.auth-wrapper .form-control:focus {
  /* border-color: #167bff; */
  box-shadow: none;
}

.custom-control-label {
  font-weight: 400;
}

