.vignette-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh; /* Ensure the container takes full height of the viewport */
    margin: 0 auto; /* Center horizontally */
}

  
  .vignette {
    position: relative;
    width: 350px;
    height: 350px;
  }
  
  .outer-circle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  
  .inner-circle {
    position: absolute;
    top: 10%;
    left: 10%;
    width: 80%;
    height: 80%;
    border-radius: 50%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  
  .logo-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('/policeLogo.png') no-repeat center center;
    background-size: cover;
    opacity: 0.2;
  }
  
  .vignette-gsr {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 55px;
    z-index: 1;
  }
  
  .vignette-plaque {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
    z-index: 1;
  }
  
  .vignette-svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .vignette-title {
    font-size: 14px;
    font-weight: bold;
    fill: white;
  }
  
  .vignette-title-arabic {
    position: absolute;
    bottom: 20%;
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: white;
  }
  
  .vignette-details {
    position: absolute;
    bottom: 10%;
    width: 100%;
    text-align: center;
    font-size: 12px;
    z-index: 1;
  }
  
  .vignette-details p {
    margin: 5px 0;
  }
  
  .qr-code {
    margin-top: 10px;
    z-index: 1;
  }
  @media print {
    .no-print {
      display: none;
    }
  }
  