.columns {
    display: flex;
    flex-wrap: wrap;
  }
  
  .column {
    flex: 1;
    padding: 0.75rem;
  }
  
  .field {
    margin-bottom: 1rem;
  }
  